import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/guards/auth.guard';
import { FormulaGuard } from './fiveElements/guards/formula.guard';
import { UnAuthGuard } from './auth/guards/un-auth.guard';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        AuthService,
        AuthGuard,
        UnAuthGuard,
        FormulaGuard,
        LocalNotifications,
    ],
})
export class CoreModule {}
