import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { AuthService } from '../auth.service';
import { LoginState } from '../enums';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {}

    public async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const uid = await this.authService.uid();
        const isLoggedIn = !!uid;

        if(!isLoggedIn) {
            this.router.navigateByUrl('/login');
        }

        return isLoggedIn;
    }
}
