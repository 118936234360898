import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, from, Observable } from 'rxjs';

import { Storage } from '@ionic/storage';

import { NetworkService } from '..';
import { DailyElement } from './interfaces/daily-element.interface';
import { distinctUntilChanged, take, map } from 'rxjs/operators';

import { AuthService } from '../../core/auth/auth.service';
import { DatabaseService } from '../../core/database/database.service';

import moment from 'moment';
@Injectable({
    providedIn: 'root',
})
export class FiveElementsService {
    private elements: any = {
        wood: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        fire: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        earth: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        metal: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        water: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
    };

    public dailyElementsOfYear: Observable<DailyElement[]> = null;
    public dailyElements: BehaviorSubject<DailyElement> = new BehaviorSubject<DailyElement>(null);
    public heavenlyEffectsDescription: string[];

    public daymaster: BehaviorSubject<Object> = new BehaviorSubject<Object>({});
    public daymasterDescription: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public fiveElements: BehaviorSubject<Object> = new BehaviorSubject<Object>({});

    constructor(
        private readonly networkService: NetworkService,
        private readonly storage: Storage,
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly database: DatabaseService
    ) {
        this.setupDefaultValues();
    }

    public async hasFormula(): Promise<boolean> {

        const cachedElements = await this.storage.get('nma:userElements');
        return !!cachedElements;
    }

    public async getFormula(userDetails: Object): Promise<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', '*');

        let params: HttpParams = new HttpParams();
        params = params.append('year', userDetails['year']);
        params = params.append('month', userDetails['month']);
        params = params.append('day', userDetails['day']);
        params = params.append('hour', userDetails['hours']);
        params = params.append('minutes', userDetails['minutes']);
        params = params.append('city', userDetails['city']);

        const httpOptions = {
            headers: headers,
            params: params,
        };

        return this.networkService.get('calculate', httpOptions).toPromise();
    }

    public async setupFormulaApi(userDetails: Object): Promise<any> {
        const formula = await this.getFormula(userDetails);

        if (formula) {
            const elements = formula.fiveElements.elements;

            elements.forEach(e => {
                this.elements[e.name]['yin'] = e.yin;
                this.elements[e.name]['yang'] = e.yang;
                this.elements[e.name]['arrows'] = e.arrows;
            });

            this.storage.set('nma:userElements', this.elements);
            this.fiveElements.next(this.elements);

            this.storage.set('nma:userDaymaster', formula.fiveElements.daymaster);
            this.daymaster.next(formula.fiveElements.daymaster);

            this.elements && this.router.navigateByUrl('/');

        }
    }

    public async setupFormula(formula: any, isAdmin? : boolean): Promise<any> {
        if (formula && formula.elements) {
            const elements = formula.elements;

            elements.forEach(e => {
                this.elements[e.name]['yin'] = e.yin;
                this.elements[e.name]['yang'] = e.yang;
                this.elements[e.name]['arrows'] = e.arrows;
            });

            !isAdmin && await this.storage.set('nma:userElements', this.elements);
            this.fiveElements.next(this.elements);

            !isAdmin && await this.storage.set('nma:userDaymaster', formula.daymaster);
            this.daymaster.next(formula.daymaster);

            this.getDaymasterDescription();
        }
    }

    public async waitForFormula(): Promise<any> {
        this.auth.user$
            .pipe(distinctUntilChanged())
            .subscribe(user => {
                if (user && user.formulaRef) {
                    const formula$ = this.database.document$(
                        `formulas/${user.formulaRef}`
                    );

                    formula$.pipe(take(1)).subscribe(async f => {
                        await this.setupFormula(f);
                        this.router.navigateByUrl('/');
                    });
                }
        });
    }

    public async setupDefaultValues(): Promise<any> {
        const cachedDaymaster = await this.storage.get('nma:userDaymaster');
        const cachedFiveElements = await this.storage.get('nma:userElements');

        if (cachedDaymaster && cachedFiveElements) {
            this.daymaster.next(cachedDaymaster);
            this.fiveElements.next(cachedFiveElements);
        } else {
            //this.waitForFormula();
        }

        this.waitForFormula();

        const cachedDaysOfYear = await this.storage.get('nma:userDaysOfYear');
        if (cachedDaysOfYear) {
            this.dailyElementsOfYear = cachedDaysOfYear;
        } else {
            const thisYear = Number(moment().format('YYYY'));
            this.dailyElementsOfYear = await this.getDailyElementsOfYear(
                thisYear
            );

            this.getTodaysElements();
        }

        await this.getDaymasterDescription();
    }

    public getDaymaster(): Promise<any> {
        return this.daymaster
            .pipe(
                take(1),
                map(daymaster => daymaster)
            )
            .toPromise()
    }

    public async getDailyElementsOfYear(year: number): Promise<any> {
        return await this.networkService.getFullPath(`/assets/dailyElements/dailyElements-${year}.json`);
    }

    public getDailyElements(month: number, day: number): void {
        const now = moment();
        const year = Number(now.format('YYYY'));
        const requestDateISO = moment()
            .year(year)
            .month(month - 1)
            .date(day);

        this.dailyElementsOfYear.subscribe(deoy => {
            const todays = deoy.find(d => d.date === requestDateISO.format('YYYY-MM-DD'));
            deoy &&
                this.dailyElements.next(todays);
                this.setHeavenlyEffetsDescription(todays.elements)
        });
    }

    public getTodaysElements(): void {
        const now = moment();
        const month = Number(now.format('M'));
        const day = Number(now.format('D'));

        this.getDailyElements(month, day);
    }

    public async getDaymasterDescription() {

        const descriptions = await this.networkService.getFullPath(`/assets/daymasterDescriptions.json`).toPromise();
        
        this.daymaster
            .pipe(take(1))
            .subscribe(( dm : any ) => {
                const description = descriptions[`${dm.polarity}_${dm.element}`];
                this.daymasterDescription.next(description);
            });
    }

    public async setDaymasterDescription(polarity, element) {

        const descriptions = await this.networkService.getFullPath(`/assets/daymasterDescriptions.json`).toPromise();

        const description = descriptions[`${polarity}_${element}`];
        description && this.daymasterDescription.next(description);

    }

    public async setHeavenlyEffetsDescription(todaysElements) {

        const heavenlyDescriptions = await this.networkService.getFullPath(`/assets/dailyEffects/heavenlyEffects.json`).toPromise();
        const todaysHeavenly = heavenlyDescriptions[todaysElements.shen];
        

        this.daymaster
            .pipe(take(1))
            .subscribe((dm: any) => {
                const dmHeavenly = todaysHeavenly[`${dm.polarity}_${dm.element}`];
                dmHeavenly && (this.heavenlyEffectsDescription = dmHeavenly);
            });

    }
}
