import { Component, ViewEncapsulation } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from './core/auth/auth.service';
import { LoginState } from './core/auth/enums';
import { LoadingModal } from './modals/loading/loading.modal';

import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    constructor(
        private readonly platform: Platform,
        private readonly splashScreen: SplashScreen,
        private readonly statusBar: StatusBar,
        private readonly modalCtrl: ModalController,
        private readonly authService: AuthService,
        private readonly router: Router,
        private screenOrientation: ScreenOrientation
    ) {
        this.initializeApp();
    }

    public initializeApp(): void {
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                this.statusBar.styleDefault();

                this.splashScreen.hide();

                this.screenOrientation.lock(
                    this.screenOrientation.ORIENTATIONS.PORTRAIT
                );
            }
        });
    }
}
