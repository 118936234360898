import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { map, take } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class DatabaseService {
    constructor(public afs: AngularFirestore) {}

    /**
     * @param {string} path of the 'collection'
     * @param {object} query 
     *
     * Retreives a collection with additional collection ID added.
     */
    public collection$(path: string, query?: any): Observable<any> {
        return this.afs
            .collection(path, query)
            .snapshotChanges()
            .pipe(
                map(actions => {
                    return actions.map(a => {
                        const data: Object = a.payload.doc.data();
                        const id: String = a.payload.doc.id;

                        return { id, ...data };
                    });
                })
            );
    }

    /**
     * @param {string} path of the document at 'collection/docID'
     *
     * Retrieves a document with additional doc ID added.
     */
    public document$(path: string): Observable<any> {
        return this.afs
            .doc(path)
            .snapshotChanges()
            .pipe(
                map(doc => {
                    const data: Object = doc.payload.data();
                    const id: String = doc.payload.id;

                    return { id, ...data };
                })
            );
    }

    /**
     * @param {string} path 'collection' or 'collection/docID'
     * @param {object} data new data
     *
     * Creates or updates data on a collection or document.
     */

    public async updateAt(path: string, data: any): Promise<any> {
        const segments = path.split('/').filter(v => v);

        return segments.length % 2
            ? await this.afs.collection(path).add(data)
            : await this.afs.doc(path).set(data, { merge: true });
    }

    /**
     * @param {string} path deletes a document on 'collection/docID'
     *
     * Deletes a document.
     */
    public delete(path: string): Promise<any> {
        return this.afs.doc(path).delete();
    }
}
