import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth/auth.service';
import { DatabaseService } from '../../core/database/database.service';

import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

@Component({
    selector: 'app-tongue-modal',
    templateUrl: 'tongue.modal.html',
    styleUrls: ['tongue.modal.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TongueModal implements OnInit {

    client;

    // Main task 
    task: AngularFireUploadTask;

    // Progress monitoring
    percentage: Observable<number>;

    snapshot: Observable<any>;

    // Download URL
    downloadURL: Observable<string>;

    // State for dropzone CSS toggling
    isHovering: boolean;

    constructor(
        private readonly authService: AuthService,
        private readonly database: DatabaseService,
        private readonly storage: AngularFireStorage, 
        private readonly db: AngularFirestore) { }


    ngOnInit() {
        console.log("client", this.client);
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }


    startUpload(event: FileList) {
        // The File object
        const file = event.item(0)

        // Client-side validation example
        if (file.type.split('/')[0] !== 'image') {
            console.error('unsupported file type :( ')
            return;
        }

        // The storage path
        const path = `tongue/${new Date().getTime()}_${file.name}`;

        // Totally optional metadata
        //const customMetadata = { app: 'My AngularFire-powered PWA!' };

        // The main task
        this.task = this.storage.upload(path, file)

        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
            // The file's download URL
            tap(async snap => {
                console.log(snap)
                if (snap.bytesTransferred === snap.totalBytes) {
                    // Update firestore on completion
                    const fileRef = snap.ref;
                    const downloadURL = await fileRef.getDownloadURL();
                    this.downloadURL = downloadURL;

                    const storagePath = `tongue/${new Date().getTime()}_${file.name}`;
                    const photo = { 
                        storagePath, 
                        size: snap.totalBytes, 
                        downloadURL,
                        dateAdded: new Date().toLocaleString()
                    }

                    const cp = (this.client.datasheet && this.client.datasheet.tonguePhotos) || [];
                    cp.push(photo);
                    
                    const userPath = `users/${this.client.id}`;
                    await this.database.updateAt(userPath, { datasheet: { tonguePhotos: cp } });
                }
            })
        )
    }



    // Determines if the upload task is active
    isActive(snapshot) {
        return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
    }

 }
