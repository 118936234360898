import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate } from '@angular/fire/auth-guard';

import { AuthGuard } from './core/auth/guards/auth.guard';
import { UnAuthGuard } from './core/auth/guards/un-auth.guard';
import { AdminGuard } from './core/auth/guards/admin.guard';
import { FormulaGuard } from './core/fiveElements/guards/formula.guard';


import { map } from 'rxjs/operators';

import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { AppComponent } from './app.component';

const redirectToHomeOrLogin = map(user => user ? ['', 'home'] : ['login']);
const redirectLoggedInToHome = redirectLoggedInTo(['home']);
const redirectUnauthorizedToLogin = redirectUnauthorizedTo(['login']);

const routes: Routes = [
    {
        path: '',
        canActivate: [AngularFireAuthGuard, FormulaGuard],
        loadChildren: './modules/home/home.module#HomePageModule',
    },
    {
        path: 'admin',
        canActivate: [AngularFireAuthGuard, AdminGuard],
        loadChildren: './modules/admin/admin.module#AdminPageModule',
    },
    {
        path: 'login',
        canActivate: [UnAuthGuard],
        loadChildren: './modules/login/login.module#LoginPageModule',
    },
    {
        path: 'registration',
        loadChildren:
            './modules/registration/registration.module#RegistrationPageModule',
    },
    {
        path: 'date-place',
        canActivate: [FormulaGuard],
        loadChildren:
            './modules/date-place/date-place.module#DatePlacePageModule',
    },
    {
        path: 'daily-elements',
        canActivate: [AuthGuard],
        loadChildren:
            './modules/daily-elements/daily-elements.module#DailyElementsPageModule',
    },
    {
        path: 'daily-questions',
        canActivate: [AuthGuard],
        loadChildren:
            './modules/daily-questions/daily-questions.module#DailyQuestionsPageModule',
    },
    {
        path: 'element-description',
        canActivate: [AuthGuard],
        loadChildren:
            './modules/element-description/element-description.module#ElementDescriptionPageModule',
    },
    {
        path: 'export',
        canActivate: [AuthGuard],
        loadChildren: './modules/export/export.module#ExportPageModule',
    },
    {
        path: 'notes',
        canActivate: [AuthGuard],
        loadChildren: './modules/notes/notes.module#NotesPageModule',
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: './modules/profile/profile.module#ProfilePageModule',
    },
    {
        path: 'tutorial',
        loadChildren: './modules/tutorial/tutorial.module#TutorialPageModule',
    },

    { path: 'tos', loadChildren: './modules/tos/tos.module#TosPageModule' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
