import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { FiveElementsService } from '../five-elements.service';

@Injectable({
    providedIn: 'root',
})
export class FormulaGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly fiveElementsService: FiveElementsService
    ) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const uid = await this.authService.uid();
        const isLoggedIn = !!uid;

        const hasFormula = await this.fiveElementsService.hasFormula();

        let access = true;

        if(!isLoggedIn) {
            this.router.navigateByUrl('/login');
            access = false;
        }

        if((state.url === '/date-place') && hasFormula) {
            this.router.navigateByUrl('/');
            access = false;
        } else if ((state.url === '/') && !hasFormula) {
            this.router.navigateByUrl('/date-place');
            access = false;
        }

        return access;
    }
}
