import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
    BrowserModule,
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ModalsModule } from './modals/modals.module';

import { environment } from '../environments/environment';

import * as Hammer from 'hammerjs';
export class CustomHammerConfig extends HammerGestureConfig {
    public overrides: any = {
        pan: {
            direction: Hammer.DIRECTION_ALL,
        },

        swipe: {
            direction: Hammer.DIRECTION_ALL,
        },
    };
}
@NgModule({
    declarations: [
        AppComponent,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'ios',
        }),
        IonicStorageModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireAuthGuardModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        ModalsModule,
        AppRoutingModule,
    ],
    exports: [FormsModule, ReactiveFormsModule],
    providers: [
        StatusBar,
        SplashScreen,
        ScreenOrientation,
        GooglePlus,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
