import { EnvironmentInterface } from "./environment.interface";

export const environment: EnvironmentInterface = {
  production: false,
  apiUrl: "http://us-central1-daymaster-engine.cloudfunctions.net",
  firebase: {
    apiKey: "AIzaSyAwKoIbAw4KAtaGtD3l5OhLapeCqHQHOHQ",
    authDomain: "otelem-app.firebaseapp.com",
    databaseURL: "https://otelem-app.firebaseio.com",
    projectId: "otelem-app",
    storageBucket: "otelem-app.appspot.com",
    messagingSenderId: "662365348738"
  }
};
