import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { NetworkService } from '../../core/network/network.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-calculation',
    templateUrl: './calculation.page.html',
    styleUrls: ['./calculation.page.scss'],
})
export class CalculationPage implements OnInit {
    public customPickerOptions: any;
    public bornCity: string;
    public bornDate: Object;

    public elements: any = {
        wood: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        fire: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        earth: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        metal: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
        water: {
            yin: 0,
            yang: 0,
            arrows: {},
        },
    };

    public daymaster: string;

    constructor(
        private modalController: ModalController,
        private networkService: NetworkService
    ) {
        this.customPickerOptions = {
            buttons: [
                {
                    text: 'Mentés',
                    handler: $event => {
                        const values = $event;

                        const keys = Object.keys(values);

                        const date: Object = {};

                        keys.map(key => {
                            date[key] = String(values[key].value || 0);
                        });

                        this.bornDate = date;
                    },
                },
            ],
        };
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data

        this.modalController.dismiss({
            elements: this.elements,
            daymaster: this.daymaster,
        });
    }

    public async calculate(): Promise<any> {
        const data = {
            ...this.bornDate,
            city: this.bornCity,
        };

        const formula = await this.getFormula(data);

        if (formula) {
            const elements = formula.fiveElements.elements;

            elements.forEach(e => {
                this.elements[e.name]['yin'] = e.yin;
                this.elements[e.name]['yang'] = e.yang;
                this.elements[e.name]['arrows'] = e.arrows;
            });

            this.daymaster = `${formula.fiveElements.daymaster.polarity} ${formula.fiveElements.daymaster.element}`;
        }

        this.dismiss();
    }

    public async getFormula(userDetails: Object): Promise<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', '*');

        let params: HttpParams = new HttpParams();
        params = params.append('year', userDetails['year']);
        params = params.append('month', userDetails['month']);
        params = params.append('day', userDetails['day']);
        params = params.append('hour', userDetails['hour']);
        params = params.append('minutes', userDetails['minute']);
        params = params.append('city', userDetails['city']);

        const httpOptions = {
            headers: headers,
            params: params,
        };

        return this.networkService.get('calculate', httpOptions).toPromise();
    }

    ngOnInit() {}
}
