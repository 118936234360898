import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TutorialModal } from './tutorial/tutorial.modal';
import { FormulaModal } from './formula/formula.modal';
import { LoadingModal } from './loading/loading.modal';
import { TongueModal} from './tongue-upload/tongue.modal';

import { CalculationPage } from './calculation/calculation.page';

import { DropZoneDirective } from '../core/drop-zone.directive';
import { FileSizePipe } from '../core/file-size.pipe';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [ TutorialModal, FormulaModal, LoadingModal, CalculationPage, TongueModal, DropZoneDirective, FileSizePipe ],
    entryComponents: [ TutorialModal, FormulaModal, LoadingModal, CalculationPage, TongueModal ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalsModule {}
