import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';

import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class UnAuthGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
      const uid = await this.authService.uid();
      const isLoggedIn = !!uid;

      if (isLoggedIn) {
          this.router.navigateByUrl('/');
      }

      return !isLoggedIn;
    }
}
