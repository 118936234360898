import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { take, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { auth, User } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { GooglePlus } from '@ionic-native/google-plus/ngx';


import { DatabaseService } from '../database/database.service';
import { LoginState } from './enums';
import { environment } from '../../../environments/environment';
import { FirebaseFacebookInterface } from './interfaces';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public loginState$: BehaviorSubject<LoginState> = new BehaviorSubject(
        LoginState.NotLoggedIn
    );
    public authState$: Observable<any>;
    public user$: Observable<any>;
    public user: Object;

    public loading: HTMLIonLoadingElement;

    constructor(
        // private readonly networkService: NetworkService,
        public readonly afAuth: AngularFireAuth,
        private readonly database: DatabaseService,
        private readonly router: Router,
        private readonly storage: Storage,
        private readonly platform: Platform,
        private readonly loadingController: LoadingController,
        private readonly alertController: AlertController,
        private readonly googlePlus: GooglePlus,
    ) {
        this.authState$ = this.afAuth.authState;
        this.user$ = this.authState$.pipe(
            switchMap(user => {
                if (user) {

                    const user$ = this.database.document$(`users/${user.uid}`);
                    
                    user.uid && this.updateUserData(user);
                    localStorage.setItem(
                        'cachedUser',
                        JSON.stringify(<User>user)
                    );

                    this.user = user;

                    return user$;

                 } else {
                     return of(null);
                 }
            })
        );
    }

    public async anonymousLogin(): Promise<any> {
        const credentials = await this.afAuth.auth.signInAnonymously();

        return await this.updateUserData(credentials.user);
    }

    public async updateUserData({
        uid,
        email,
        displayName,
        photoURL,
        isAnonymous,
    }: any): Promise<any> {
        const path = `users/${uid}`;

        const data = {
            uid,
            email,
            displayName,
            photoURL,
            isAnonymous,
        };

        return await this.database.updateAt(path, data);
    }

    public async updateUserBirthdata({ 
        year, 
        month, 
        day, 
        hours, 
        minutes, 
        city 
    }: any): Promise<any> {

        const user: any = this.user;            

        if (user) {

            const dateString = `${year};${month};${day};${hours};${minutes}`
            const bornCity = city;

            const path = `users/${user.uid}`;
            
            await this.database.updateAt(path, { bornCity });
            const result = await this.database.updateAt(path, { dateISO: dateString });
            
            return result;
            
        };

    }

    public async signOut(): Promise<any> {
        await this.afAuth.auth.signOut();
        this.user$ = null;

        await this.storage.remove("nma:userDaymaster");
        await this.storage.remove("nma:userElements");
        await this.storage.remove("nma:userDateAndPlace");

        this.router.navigateByUrl('/login');
        
        return true;
    }

    public uid(): Promise<any> {
        return this.user$
            .pipe(
                take(1),
                map(user => user && user.uid)
            )
            .toPromise()
    }

    public isAdmin(): Promise<any> {
        return this.user$
            .pipe(
                take(1),
                map(async user => {
                    const token = await this.afAuth.auth.currentUser.getIdTokenResult(true);
                    return token.claims.admin;
                })
            )
            .toPromise();
    }

    public isSuperAdmin(): Promise<any> {
        return this.user$
            .pipe(
                take(1),
                map(async user => {
                    const token = await this.afAuth.auth.currentUser.getIdTokenResult(true);
                    return token.claims.superAdmin;
                })
            )
            .toPromise();
    }

    public async signupUser(fullName: string, email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(
                async response => {
                    const user = response.user;

                    const data = {
                        uid: user.uid,
                        email: user.email,
                        displayName: fullName,
                        photoURL: '',
                        isAnonymous: false
                    };

                    user && await this.updateUserData(data);

                    resolve(response);
                },
                error => {
                    this.showError(error);
                    reject(error);
                }
            );
        });
    }

    public emailLogin(email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(email, password).then(
                response => {
                    resolve(response);
                },
                error => {
                    this.showError(error);
                    reject(error);
                }
            );
        });
    }

    /// --- Google Auth ///

    public setRedirect(value: boolean): void {
        this.storage.set('authRedirect', value);
    }

    public async isRedirect(): Promise<any> {
        return await this.storage.get('authRedirect');
    }

    public async loginWithFacebookPopup(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const provider = new auth.FacebookAuthProvider();

            provider.addScope('user_birthday');
            provider.addScope('email');

            this.afAuth.auth.signInWithPopup(provider).then(
                response => {
                    resolve(response);
                },
                error => {
                    this.showError(error);
                    reject(error);
                }
            );
        });
    }

    // public async loginWithFacebookRedirect(): Promise<any> {
    //     try {
    //         if (this.platform.is('cordova')) {
    //             await this.nativeFacebookLogin();
    //         } else {
    //             await this.setRedirect(true);

    //             const provider = new auth.FacebookAuthProvider();

    //             provider.addScope('user_birthday');
    //             provider.addScope('email');

    //             return await this.afAuth.auth.signInWithRedirect(provider);
    //         }
    //     } catch (error) {
    //         this.showError(error);
    //     }
    // }

    // public async nativeFacebookLogin(): Promise<any> {
    //     const facebookUser: FacebookLoginResponse =
    //         await this.facebook.login(
    //             [
    //                 'public_profile',
    //                 'email'
    //             ]
    //         );

    //     return await this.afAuth.auth.signInWithCredential(
    //         auth.FacebookAuthProvider.credential(facebookUser.authResponse.accessToken)
    //     );
    // }

    public async loginWithGooglePopup(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const provider = new auth.GoogleAuthProvider();

            provider.addScope('profile');
            provider.addScope('email');

            this.afAuth.auth.signInWithPopup(provider).then(
                response => {
                    resolve(response);
                },
                error => {
                    this.showError(error);
                    reject(error);
                }
            );
        });
    }

    public async loginWithGoogleRedirect(): Promise<any> {
        try {
            if (this.platform.is('cordova')) {
                await this.nativeGoogleLogin();
            } else {
                await this.setRedirect(true);

                const provider = new auth.GoogleAuthProvider();

                provider.addScope('profile');
                provider.addScope('email');

                return await this.afAuth.auth.signInWithRedirect(provider);
            }
        } catch (error) {
            this.showError(error);
        }
    }

    public async nativeGoogleLogin(): Promise<any> {
        const gplusUser = await this.googlePlus.login({
            webClientId:
                '662365348738-d510o3n8hg3845mjl6pa2hv8c69sptu9.apps.googleusercontent.com',
            offline: true,
            scopes: 'profile email',
        });

        return await this.afAuth.auth.signInWithCredential(
            auth.GoogleAuthProvider.credential(gplusUser.idToken)
        );
    }

    private async showError(errorMessage: string): Promise<any> {

        const alert = await this.alertController.create({
            message: errorMessage,
            buttons: [{ text: 'Ok', role: 'cancel' }],
        });
        await alert.present();
    }
}
