import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RequestOptions } from './interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    constructor(private readonly http: HttpClient) {}

    public get<T>(endPoint: string, options?: RequestOptions): Observable<T> {
        return this.http.get<T>(`${environment.apiUrl}/${endPoint}`, options);
    }

    public getFullPath<T>(fullPath: string, options?: RequestOptions): Observable<T> {
        return this.http.get<T>(`${fullPath}`, options);
    }

    public post<T>(
        endPoint: string,
        body: Object,
        options?: RequestOptions
    ): Observable<T> {
        return this.http.post<T>(
            `${environment.apiUrl}/${endPoint}`,
            body,
            options
        );
    }

    public put<T>(
        endPoint: string,
        body?: Object,
        options?: RequestOptions
    ): Observable<T> {
        return this.http.put<T>(
            `${environment.apiUrl}/${endPoint}`,
            body,
            options
        );
    }

    public delete<T>(
        endPoint: string,
        options?: RequestOptions
    ): Observable<T> {
        return this.http.delete<T>(
            `${environment.apiUrl}/${endPoint}`,
            options
        );
    }

    public patch<T>(
        endPoint: string,
        body?: Object,
        options?: RequestOptions
    ): Observable<T> {
        return this.http.patch<T>(
            `${environment.apiUrl}/${endPoint}`,
            body,
            options
        );
    }
}
